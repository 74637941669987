.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 2s;
}

.fade-in.is-visible {
  opacity: 1;
}

#main {
  min-height: calc(100vh - 48px - 99px);
}

.choose-result:hover{
  border: solid 1px black;
}

.primary {
  background-color: var(--secondary-color);
}

body{
  background-color: var(--secondary-color);
  color: var(--contrast-color);
}

#main a{
  color: #b398ff;
}

/* .secondary {
  color: #5b0077;
  color: #00780c;
} */

.tertiary {
  background-color: #f4f201;
}

:root {
  /* Changes to this color must also be reflected in app.tsx -> Theme */
  --secondary-color: #151226;
  --contrast-color: #BF307F;
}


.container{
  font-family: "arial";
  font-size: 24px;
  display: grid;
  place-items: center;
  width: 100%;
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  border-right: none;
  width: 0;
  animation: typing 2s steps(30, end) forwards, blinking 0.5s steps(30, end) infinite, reset-typing 10s forwards;
}
  
@keyframes typing {
  from { width: 0%; border-right: 2px solid; }
  to { width: 100% }
}

@keyframes blinking {
  0% {border-color: transparent}
  50% {border-color: var(--contrast-color)}
  100% {border-color: transparent}
}

@keyframes reset-typing {
  100% {white-space: normal;}
}
